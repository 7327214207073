import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import secImg from "../../../assets/images/services1.png";
import dashImg from "../../../assets/images/Dashboard.svg";
import Icon from "../../../assets/images/Icon.svg";
import icon1 from "../../../assets/images/Icon (1).svg";
import icon2 from "../../../assets/images/Icon (2).svg";
import icon3 from "../../../assets/images/Icon (3).svg";
import LandingNav from "../Navbar/LandingNav";
import Footer from "../Footer/Footer";
import ScrollToTopButton from "../../../Components/Scroll/ScrolDown";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import openAiImage from "../../../assets/images/openAiImage.png";
import secongImage from "../../../assets/images/amico.svg";
import thirdImage from "../../../assets/images/openAiCarsule.svg";
import { signUp_Urls } from "../../../URLS/staticUrls";

function Services() {
  const { t } = useTranslation();
  const Login_URL = signUp_Urls;

  return (
    <>
      <LandingNav />
      <section>
        <Container>
          <Row className='align-items-center'>
            {/* Text Column */}
            <Col sm={12} md={6} className='order-md-1 order-2'>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>
                  <h1>
                    {t("service.sendSign")}
                    <br />
                    {t("service.anywhere")}
                  </h1>
                  <span>
                    {t("service.superCharge")}
                    <br />
                    {t("service.NavigateAgreements")}
                  </span>
                  <div className='my-4'>
                    <Link to={Login_URL}>
                      <button className='getStartedbtn'>{t("service.GetStarted")}</button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>

            {/* Image Column */}
            <Col md={6} className='order-md-2 order-1'>
              <img src={secImg} alt='imageLoading' className='img-fluid' style={{ maxWidth: "100%" }} />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <div>
          <div className='col-12'>
            <div style={{ backgroundColor: "#FEF1CD", padding: "100px", display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
              <div style={{ backgroundColor: "#fbfcf380", padding: "20px", width: "30rem" }}>
                <img src={openAiImage} alt='ImageLoading' style={{ height: "200px", width: "275px" }} />
                <h3 className='fw-bold my-2'>{t("service.openAI")}</h3>
                <p>{t("service.generateNewDocuments")}</p>
              </div>

              <div style={{ backgroundColor: "#fbfcf380", padding: "20px", width: "30rem" }}>
                <img src={thirdImage} alt='ImageLoading' style={{ height: "200px", width: "275px" }} />
                <h3 className='fw-bold my-2'>{t("service.generateCustomize")}</h3>
                <p>{t("service.AI-poweredDocument")}</p>
              </div>
              <div style={{ backgroundColor: "#fbfcf380", padding: "20px", width: "30rem" }}>
                <img src={secongImage} alt='ImageLoading' style={{ height: "200px", width: "275px" }} />
                <h3 className='fw-bold my-2'>{t("service.DocumentsType")}</h3>
                <p>{t("service.CraftYour")}</p>
              </div>
              <div
                style={{
                  backgroundColor: "#0C9AD7",
                  padding: "110px 90px",
                  width: "30rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <h2 className='fw-bold my-2' style={{ color: "#FFFFFF" }}>
                  {t("service.Joinour")}
                </h2>
                <Link to={Login_URL}>
                  <Button style={{ padding: "10px 50px", color: "#0C9AD7", backgroundColor: "#FFFFFF", fontWeight: "500", border: "#0C9AD7" }} variant='none'>
                    {t("service.GetStarted")}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#f9faf7" }}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='text-md-center text-left'>
                <div style={{ padding: "30px 2px" }}>
                  <h1 style={{ fontWeight: "700" }}>{t("service.UltimateSolution")}</h1>
                  <span className='mt-3'>
                    {t("service.unlockSeamless")}
                    <br />
                    {t("service.streamlineProcess")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div>
            <div className='col-12 px-3'>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#f9faf7", padding: "20px 2px" }}>
                <div style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                  <img src={dashImg} alt='imageLoading' className='img-fluid' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div style={{ backgroundColor: "#f7fcff", padding: "50px" }}>
          <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
            <div style={{ width: "50%", maxWidth: "300px", marginBottom: "20px", textAlign: "center" }}>
              <img alt='imageLoading' src={Icon} />
              <h1 style={{ color: "#0979A9" }}>5+</h1>
              <span>{t("service.YearExperience")}</span>
            </div>
            <div style={{ width: "50%", maxWidth: "300px", marginBottom: "20px", textAlign: "center" }}>
              <img alt='imageLoading' src={icon1} />
              <h1 style={{ color: "#0979A9" }}>5960</h1>
              <span>{t("service.Satisfiedclients")}</span>
            </div>
            <div style={{ width: "50%", maxWidth: "300px", marginBottom: "20px", textAlign: "center" }}>
              <img alt='imageLoading' src={icon2} />
              <h1 style={{ color: "#0979A9" }}>50k</h1>
              <span>{t("service.ITproblemSolved")}</span>
            </div>
            <div style={{ width: "50%", maxWidth: "300px", marginBottom: "20px", textAlign: "center" }}>
              <img alt='imageLoading' src={icon3} />
              <h1 style={{ color: "#0979A9" }}>25+</h1>
              <span>{t("service.Proteammembers")}</span>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTopButton />
      <Footer />
    </>
  );
}

export default Services;
