// import React from "react";
// import { Dropdown } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
// import { TbWorld } from "react-icons/tb";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import { useNavigate } from "react-router-dom";

// function LangSelector() {
//   const { t } = useTranslation();
//   const { i18n } = useTranslation();
//   const navigate = useNavigate();

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);

//     document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";
//   };

//   return (
//     <Dropdown className='user_settings'>
//       <Dropdown.Toggle variant='none' id='dropdownMenuButton4' style={{ color: "white" }}>
//         <TbWorld style={{ color: "#0C9AD7 ", padding: "0px" }} size={24} />
//       </Dropdown.Toggle>

//       <Dropdown.Menu style={{ left: "auto", right: 0, minWidth: "unset", padding: "0px" }}>
//         <Dropdown.Item onClick={() => changeLanguage("en")}>{t("languageSelector.english")}</Dropdown.Item>
//         <NavDropdown.Divider />
//         <Dropdown.Item onClick={() => changeLanguage("ar")}>{t("languageSelector.arabic")}</Dropdown.Item>
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// }

// export default LangSelector;

import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TbWorld } from "react-icons/tb";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate, useLocation } from "react-router-dom";

function LangSelector() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";

    const urlParams = new URLSearchParams(location.search);
    urlParams.set("lang", lng);
    navigate(location.pathname + "?" + urlParams.toString());
  };

  return (
    <Dropdown className='user_settings'>
      <Dropdown.Toggle variant='none' id='dropdownMenuButton4' style={{ color: "white" }}>
        <TbWorld style={{ color: "#0C9AD7 ", padding: "0px" }} size={24} />
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ left: "auto", right: 0, minWidth: "unset", padding: "0px" }}>
        <Dropdown.Item onClick={() => changeLanguage("en")}>{t("languageSelector.english")}</Dropdown.Item>
        <NavDropdown.Divider />
        <Dropdown.Item onClick={() => changeLanguage("ar")}>{t("languageSelector.arabic")}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LangSelector;
