import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./Components/Language/i18n.js";
import HomeLanding from "./Pages/LandingPages/Screens/HomeLanding.js";
import Contactus from "./Pages/LandingPages/Screens/Contactus.js";
import PrivacyandPolicy from "./Pages/LandingPages/Screens/PrivacyandPolicy.js";
import TermsandConditions from "./Pages/LandingPages/Screens/TermsandConditions.js";
import AboutUs from "./Pages/LandingPages/Screens/AboutUs.js";
import Services from "./Pages/LandingPages/Screens/Services.js";
import "./index.css";
import CustomToaster from "./Components/Toaster/CustomToaster.js";
import PricingPage from "./Pages/LandingPages/Screens/PricingPage.js";
import ApiScreen from "./Pages/LandingPages/Screens/ApiScreen.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <CustomToaster />
          <Routes>
            <Route path='/' element={<HomeLanding />} />
            <Route path='/Contactus' element={<Contactus />} />
            <Route path='/Privacy&Policy' element={<PrivacyandPolicy />} />
            <Route path='/Terms&Conditions' element={<TermsandConditions />} />
            <Route path='/Aboutus' element={<AboutUs />} />
            <Route path='/Services' element={<Services />} />
            <Route path='/Pricing' element={<PricingPage />} />
            <Route path='/Apis' element={<ApiScreen />} />
          </Routes>
        </I18nextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
