import React, { useState, useEffect } from "react";
import LandingNav from "../Navbar/LandingNav";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import upload from "../../../assets/images/upload.png";
import securityImg from "../../../assets/images/security.png";
import controlImg from "../../../assets/images/control.png";
import SignImg from "../../../assets/images/sign.png";
import macImg from "../../../assets/images/Section About IamSigner English.svg";
import macrightImg from "../../../assets/images/Section About IamSigner Arabic.svg";
import macfullImg from "../../../assets/images/Macbook Pro 13_ Mockup.svg";
import openAi from "../../../assets/images/poxwebyAi.svg";
import iphoneImg from "../../../assets/images/homeIphone.svg";
import { FaApple } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import ScrollToTopButton from "../../../Components/Scroll/ScrolDown";
import Nav from "react-bootstrap/Nav";
import "./style.css";
import { Google_Play_Link, base_Urls, signUp_Urls } from "../../../URLS/staticUrls";
import OpenAiCarsouel from "../../CarouselComponent/OpenAiCarsouel";
import Chatbot from "../../../Components/Chatbot/Chatbot";

function HomeLanding() {
  const BASE_URL = base_Urls;
  const Login_URL = signUp_Urls;
  const Google_URL = Google_Play_Link;

  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <LandingNav />
      <OpenAiCarsouel />
      <Chatbot />
      <div className='mainPage'>
        <section>
          <div className='MianBckImg'>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className='mobileSection'>
                <span>
                  {t("landingPage.seamlessSigning")}
                  <br />
                  {t("landingPage.madeQuickEasy")}
                </span>
              </div>
              <div className='mobilePara mt-2'>
                <p>
                  {t("landingPage.IamSignerSolution")}
                  <br />
                  {t("landingPage.manageContracts")}
                  <br />
                  {t("landingPage.agreementProcess")}
                </p>
              </div>
              <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                <Link to={BASE_URL}>
                  <Button className='Loginbtn'>{t("landingPage.login")}</Button>
                </Link>
                <Link to={Login_URL}>
                  <Button className='signUpbtn'>{t("landingPage.Signup")}</Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <section>
          <div
            className='usersBar'
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "space-evenly",
              padding: "40px 0px",
              gap: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h1>15k+</h1>
              <span>{t("landingPage.activeUser")}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h1>88k+</h1>
              <span>{t("landingPage.totalDownload")}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h1>12k</h1>
              <span>{t("landingPage.company")}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h1>10k</h1>
              <span>{t("landingPage.goodTestimonials")}</span>
            </div>
          </div>
        </section> */}

        <section className='featureBar'>
          <div>
            <h1 className='fw-bold'>{t("landingPage.ourTopFeatures")}</h1>
          </div>
          <div>
            <p className=''>
              {t("landingPage.withElecticArgreements")}
              <br />
              {t("landingPage.withEasySignature")}
            </p>
          </div>
          <div className='featuresContainer'>
            <div className='featuresdiv'>
              <div className='featuresChild'>
                <img loading='lazy' src={upload} alt='imageLoading' style={{ height: "60px", width: "60px" }} />
                <label className='featureslabel'>{t("landingPage.upload")}</label>
                <p>
                  {t("landingPage.Sustainable")}
                  <br />
                  {t("landingPage.documentsignature")}
                </p>
              </div>
              <div className='featuresChild'>
                <img loading='lazy' src={securityImg} alt='imageLoading' style={{ height: "60px", width: "60px" }} />
                <label className='featureslabel'>{t("landingPage.security")}</label>
                <p>
                  {t("landingPage.legalSafe")}
                  <br />
                  {t("landingPage.moresafe")}
                </p>
              </div>
              <div className='featuresChild'>
                <img loading='lazy' src={SignImg} alt='imageLoading' style={{ height: "60px", width: "60px" }} />
                <label className='featureslabel'>{t("landingPage.sign")}</label>
                <p>
                  {t("landingPage.unlimitedSign")}
                  <br />
                  {t("landingPage.anywhereAnytime")}
                </p>
              </div>
              <div className='featuresChild'>
                <img loading='lazy' src={controlImg} alt='imageLoading' style={{ height: "60px", width: "60px" }} />
                <label className='featureslabel'>{t("landingPage.control")}</label>
                <p>
                  {t("landingPage.simpleAccess")}
                  <br />
                  {t("landingPage.assistance")}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className='OpenAiSection'>
            <img loading='lazy' src={openAi} alt='imageLoading' />
            <div className='openSectionHeading'>
              <h1 className='fw-bold'>
                {t("landingPage.poweredBy")} <span style={{ fontSize: "small", color: "#9F467B" }}>{t("landingPage.newFeature")}</span>
              </h1>
              <p className='m-0'>{t("landingPage.AIAutomatedContent")}</p>
            </div>
          </div>
        </section>

        <section className='mackbookSection'>
          <img loading='lazy' src={windowWidth < 768 ? macfullImg : document.dir === "rtl" ? macrightImg : macImg} alt='imageLoading' className='macimage' />
          <div className='macSection'>
            <h1 className='fw-bold'>{t("landingPage.aboutImsigner")}</h1>
            <ul>
              {t("landingPage.IamSignerDocument")}
              <li>{t("landingPage.SimplifyWorkflow")}</li>
              <li>{t("landingPage.protectconfidentiality")} </li>
              <li>{t("landingPage.experienceuserfriendly")}</li>
              <li>{t("landingPage.EasilyOrganize")}</li>
              <li>{t("landingPage.personalizedExperience")}</li>
              <li>{t("landingPage.Ensureregulatory")}</li>
            </ul>
          </div>
        </section>

        <section className='downloadSection'>
          <div>
            <h3>
              {t("landingPage.optimize")} <br /> {t("landingPage.management")}
              <br /> {t("landingPage.today")}
            </h3>
            <p>
              {t("landingPage.TryIamSigner")} <br /> {t("landingPage.likeBefore")}
            </p>
            <div className='AppStore'>
              <Nav.Link as={Link} to='/' className='appleDiv'>
                <FaApple size={30} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className='fw-bold' style={{ textDecoration: "none" }}>
                    {t("landingPage.downloadOn")}
                  </label>
                  <label>{t("landingPage.appStore")}</label>
                </div>
              </Nav.Link>

              <Nav.Link as={Link} to={Google_URL} target='_blank' className='appleDiv'>
                <IoLogoGooglePlaystore size={30} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className='fw-bold'>{t("landingPage.downloadOn")}</label>
                  <label>{t("landingPage.googlePlay")}</label>
                </div>
              </Nav.Link>
            </div>
          </div>
          <div className='imageLayout d-none d-sm-block'>
            <img loading='lazy' src={iphoneImg} alt='imageLoading' style={{ height: "400px", width: "250px" }} />
          </div>
        </section>

        {/* <ScrollToTopButton /> */}
        <Footer />
      </div>
    </>
  );
}

export default HomeLanding;
