import React from "react";
import { CiMail } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import "./footer.css";
import { Link } from "react-router-dom";
import SaudiImg from "../../../assets/images/Flag_of_Saudi_Arabia_(type_2).svg";
import Imsigner from "../../../assets/images/IAMSignerWebLogo.png";
import JordanImg from "../../../assets/images/Flag_of_Jordan.svg";
import pakFlag from "../../../assets/images/P Flag.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section id='footer'>
      <div className='main-footer'>
        <div className='logoinfo' data-aos='fade-up'>
          <img src={Imsigner} alt=' ' style={{ height: "50px", width: "150px" }} />

          <div className='contact-details'>
            <h1>IamSigner</h1>

            <span>
              {t("footer.solutionDesigned")}
              <br />
              {t("footer.leverage")}
            </span>
          </div>
        </div>
        <div className='MainMenu' data-aos='fade-up'>
          <h1>{t("footer.mainMenu")}</h1>
          <ul>
            <li>
              <Link to='/'>
                <a>{t("footer.home")}</a>
              </Link>
            </li>
            <li>
              <Link to='/services'>
                <a>{t("footer.services")}</a>
              </Link>
            </li>
            <li>
              <Link to='/aboutus'>
                <a>{t("footer.aboutUs")}</a>
              </Link>
            </li>
            <li>
              <Link to='/contactUs'>
                <a>{t("landingHome.contactUs")}</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className='MainMenu' data-aos='fade-up'>
          <h1>{t("footer.otherLinks")}</h1>
          <ul>
            <li>
              <Link to='/Terms&Conditions'>
                <a>{t("footer.termConditions")}</a>
              </Link>
            </li>
            <li>
              <Link to='/Privacy&Policy'>
                <a>{t("footer.privacyPolicy")}</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className='info' data-aos='fade-up'>
          <h1>{t("footer.information")}</h1>
          <div className='logobox'>
            <div className='d-flex gap-2 align-items-start'>
              <CiMail size={22} />
              <span style={{ color: "#0C9AD7" }}>info@iamsigner.com</span>
            </div>
            <div className='d-flex gap-2 align-items-start'>
              <img src={SaudiImg} alt='ImageLoading' style={{ borderRadius: "50%" }} />
              <div style={{ color: "#0C9AD7" }}>
                <span>{t("footer.Riyadh")}</span>
                <br />
                <span>+966 50 006 7158</span>
                <br />
                <span>+966 50 017 2382</span>
              </div>
            </div>
            <div className='d-flex gap-2 align-items-start'>
              <img src={JordanImg} alt='ImageLoading' style={{ borderRadius: "50%" }} />
              <div style={{ color: "#0C9AD7" }}>
                <span>{t("footer.Amman")}</span>
                <br />
                <span>+962 79 111 1282</span>
              </div>
            </div>
            <div className='d-flex gap-2 align-items-start'>
              <img src={pakFlag} alt='ImageLoading' style={{ borderRadius: "50%" }} />
              <div style={{ color: "#0C9AD7" }}>
                <span>{t("footer.Islamabad")}</span>
                <br />
                <span>+92 343 9650517</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>{t("footer.rights")}</footer>
    </section>
  );
};

export default Footer;
