import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";
import chatbotImage from "../../assets/images/chatbot.png";
import { Button } from "react-bootstrap";
import "./chat.css";

const theme = {
  background: "#a0daf3",
  headerBgColor: "#0c9ad7",
  headerFontSize: "20px",
  botBubbleColor: "#0c9ad7",
  headerFontColor: "white",
  botFontColor: "white",
  userBubbleColor: "#0c9ad7",
  userFontColor: "white",
};

const config = {
  botAvatar: chatbotImage,
  floating: true,
};

const steps = [
  {
    id: "1",
    message: "Hi my name is IamSigner",
    trigger: "2",
  },
  {
    id: "2",
    message: "What is your name?",
    trigger: "3",
  },
  {
    id: "3",
    user: true,
    trigger: "4",
  },
  {
    id: "4",
    message: "Hi {previousValue}, nice to meet you!",
    trigger: "5",
  },
  {
    id: "5",
    message: "How can we help you ?",
    trigger: "6",
  },
  {
    id: "6",
    options: [
      { value: 1, label: "I'm a new user", trigger: "7" },
      { value: 2, label: "Facing problem", trigger: "11" },
      { value: 3, label: "Packages", trigger: "18" },
    ],
  },
  {
    id: "7",
    message: "Trial account is free. Create a new account for you..",
    trigger: "8",
  },
  {
    id: "8",
    message: "Let me navigate you to the registration page",
    trigger: "9",
  },
  {
    id: "9",
    options: [
      { value: 1, label: "Sure", trigger: "navigateRegistration" },
      { value: 2, label: "No", trigger: "6" },
      { value: 3, label: "Go back", trigger: "6" },
    ],
  },
  {
    id: "navigateRegistration",
    component: (
      <Button href='https://dev.iamsigner.com/signup' target='_blank' className='linkButton'>
        Click to Navigate
      </Button>
    ),
    trigger: "5",
  },
  {
    id: "11",
    message: "What problem are you facing?",
    trigger: "12",
  },
  {
    id: "12",
    options: [{ value: 1, label: "Account related...", trigger: "13" }],
  },
  {
    id: "13",
    message: "Go to contact us page and leave a  message",
    trigger: "14",
  },
  {
    id: "14",
    message: "IamSigner team will contact back to you..",
    trigger: "15",
  },
  {
    id: "15",
    message: "Let me navigate you to Contact us page?",
    trigger: "navigateContactUs",
  },
  {
    id: "navigateContactUs",
    component: (
      <Button href='/contactUs' target='_blank' className='linkButton'>
        Click to Navigate
      </Button>
    ),
    trigger: "5",
  },
  {
    id: "18",
    message: "What do you want to know about Packages?",
    trigger: "19",
  },
  {
    id: "19",
    user: true,
    trigger: "20",
  },
  {
    id: "20",
    message: "You have can see Packages details at Pricing Page",
    trigger: "21",
  },
  {
    id: "21",
    message: "For premium or custom packages freely contact us. Thank you!",
    trigger: "22",
  },
  {
    id: "22",
    user: true,
    trigger: "5",
  },
];

function Chatbot() {
  return (
    <ThemeProvider theme={theme}>
      <ChatBot headerTitle='IamSignerBot' steps={steps} {...config} />
    </ThemeProvider>
  );
}

export default Chatbot;
