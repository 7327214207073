import React from "react";
import LandingNav from "../Navbar/LandingNav";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

function TermsandConditions() {
  const { t } = useTranslation();

  return (
    <div style={{ height: "100vh" }}>
      <LandingNav />
      <div className='privacyPolicy'>
        <h2 className='fw-bold'>{t("term&conditions.termUser")}</h2>
        <label style={{ fontWeight: "600" }}>{t("term&conditions.lastModified")}</label>
        <div className='mt-3'>
          <h4>{t("term&conditions.acceptTerms")}</h4>
          <p className='mt-3 opacity-1'>{t("term&conditions.byaccessing")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.useServices")}:</h4>
          <p className='mt-3 opacity-1' style={{ opacity: "1 !important" }}>
            {t("term&conditions.certainFeatures")}
          </p>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.accountRegistration")}</h4>
          <p className='mt-3 opacity-1'>{t("term&conditions.certainFeatures")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.userResponsibilty")}</h4>
          <ul>
            <li style={{ marginLeft: "20px" }}>{t("term&conditions.responsibleMaintaining")}</li>
            <li style={{ marginLeft: "20px" }}>{t("term&conditions.YouAgree")}</li>
            <li style={{ marginLeft: "20px" }}>{t("term&conditions.YouSolely")}</li>
          </ul>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.Intellectualproperty")}</h4>
          <p className='mt-3 opacity-1'>{t("term&conditions.AllContent")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.Limitation")}</h4>
          <p className='mt-3 opacity-1'>{t("term&conditions.liableDirect")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.Termination")}</h4>
          <p className='mt-3 opacity-1'>{t("term&conditions.WeReserve")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.ChangesTerms")}</h4>
          <p>{t("term&conditions.reservetheright")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.governingLaw")}</h4>
          <p className='mt-3 opacity-1'>{t("term&conditions.TheseTermsof")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("term&conditions.ContactUs")}</h4>
          <p className='mt-3 opacity-1'>
            {t("term&conditions.anyquestions")} <span style={{ color: "#0979A9", fontWeight: "700" }}> www.IamSigner.com.</span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsandConditions;
