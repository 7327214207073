import React, { useState, useEffect } from "react";
import "./scrollstyle.css";
import { FaArrowUp } from "react-icons/fa";

function ScrollToTopButton() {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <button className='scrollToTopButton' onClick={handleScrollToTop} title='Go to top'>
          <FaArrowUp  size={20}/>
        </button>
      )}
    </>
  );
}

export default ScrollToTopButton;
