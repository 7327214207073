import React, { useEffect, useState } from "react";
import { Row, Button } from "react-bootstrap";
import LandingNav from "../Navbar/LandingNav";
import Footer from "../Footer/Footer";
import pricingIcon from "../../../assets/images/pricingIcons.svg";
import circleIcon from "../../../assets/images/Check Circle.svg";
import callIcon from "../../../assets/images/call.svg";
import "./pricingstyle.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ScrollToTopButton from "../../../Components/Scroll/ScrolDown";
import { One_time_Token } from "../../../URLS/staticUrls";
import axios from "../../../URLS/Api/Api";
import { toast } from "react-toastify";

function PricingPage() {
  const { t } = useTranslation();
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = One_time_Token;

  const fetchPricingPlan = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetPricingPlans", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const plans = response.data.response;
        // Sorting plans based on sortOrder
        plans.sort((a, b) => a.sortOrder - b.sortOrder);
        setPricingPlans(plans);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.info("Visit Later or Refresh the Page!");
      console.log("Pricing Error!", error);
    }
  };

  useEffect(() => {
    fetchPricingPlan();
  }, [token]);

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <div className='loader'></div>
        </div>
      )}
      <LandingNav />
      <section className='parentPricing '>
        <div className='my-3 container'>
          <div className='mt-5 text-center'>
            <h1 className='fw-bold'>{t("pricing.ChoosePlan")}</h1>
            <p>
              {t("pricing.esign")}
              <br />
              {t("pricing.carry")}
            </p>
          </div>

          <Row className='pricingRows'>
            {pricingPlans.map((plan) => (
              <div className='MainBox' key={plan.planID}>
                <div className='MainboxFirsStarter'>
                  <img src={plan.planName === "Enterprise" ? callIcon : pricingIcon} alt='PricingIconLoading' />
                  <div>
                    <h3 style={{ fontWeight: "500" }}>{plan.planName}</h3>
                    <p className='m-0' style={{ fontWeight: "500" }}>
                      {plan.description}
                    </p>
                  </div>
                </div>
                <div className='MainboxFirsStarter'>
                  <label style={{ fontWeight: "700", fontSize: "40px" }}>{plan.price}</label>
                  <div>
                    {/* Conditional rendering for payment period */}
                    {plan.paymentPeriod === "Let's Talk" ? (
                      <h1 style={{ fontWeight: "700", fontSize: "50px" }}>{plan.paymentPeriod}</h1>
                    ) : (
                      <label style={{ fontWeight: "500" }}>
                        {plan.currency} <br />
                        {plan.paymentPeriod}
                      </label>
                    )}
                  </div>
                </div>
                <p className='m-0'>{plan.yearlyPrice}</p>
                <div className='IncludedDiv'>
                  <h4>{t("pricing.included")}</h4>
                  {plan.details.map((detail) => (
                    <div className='includedchilds' key={detail.detailID}>
                      <img src={circleIcon} alt='CheckMarkLoading' />
                      <label>{detail.featureName}</label>
                    </div>
                  ))}
                </div>

                <div style={{ marginTop: "auto" }}>
                  <Link to='/contactUs'>
                    <Button variant='none' className='ContactUsPricing'>
                      {t("pricing.contact")}
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </section>

      <Footer />
      <ScrollToTopButton />
    </>
  );
}

export default PricingPage;
