import React from "react";
import { useTranslation } from "react-i18next";
import imSignerLogo from "../../../assets/images/IAMSignerWebLogo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import LangSelector from "../../../Components/Language/LangSelector";
import { Link, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./style.css";

function LandingNav() {
  const { t } = useTranslation();
  const location = useLocation();

  const expand = "lg";

  return (
    <Navbar expand={expand} className='bg-body-tertiary fixed-top shadow-sm' sticky='top'>
      <Container fluid>
        <Navbar.Brand as={Link} to='/'>
          <img src={imSignerLogo} alt='ImagaLoading' style={{ height: "50px", width: "150px" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement='end'>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <img src={imSignerLogo} alt='ImagaLoading' style={{ height: "50px", width: "150px" }} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className='justify-content-end flex-grow-1 pe-3'>
              <Nav.Link as={Link} to='/' className={location.pathname === "/" ? "navLinks active" : "navLinks"}>
                {t("landingHome.home")}
              </Nav.Link>
              <Nav.Link as={Link} to='/services' className={location.pathname === "/services" ? "navLinks active" : "navLinks"}>
                {t("landingHome.services")}
              </Nav.Link>
              <Nav.Link as={Link} to='/aboutus' className={location.pathname === "/aboutus" ? "navLinks active" : "navLinks"}>
                {t("landingHome.aboutUs")}
              </Nav.Link>
              <Nav.Link as={Link} to='/Apis' className={location.pathname === "/Apis" ? "navLinks active" : "navLinks"}>
                APIs
              </Nav.Link>
              <Nav.Link as={Link} to='/Pricing' className={location.pathname === "/Pricing" ? "navLinks active" : "navLinks"}>
                {t("landingHome.Pricing")}
              </Nav.Link>
              <Nav.Link as={Link} to='/contactUs' className={location.pathname === "/contactUs" ? "navLinks active" : "navLinks"}>
                {t("landingHome.contactUs")}
              </Nav.Link>
              <div className='no-hover'>
                <LangSelector />
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default LandingNav;
