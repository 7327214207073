import React, { useState, useEffect, useRef } from "react";
import LandingNav from "../Navbar/LandingNav";
import Footer from "../Footer/Footer";
import Clickimage from "../../../assets/images/click.svg";
import daimondImage from "../../../assets/images/daimond.svg";
import verifyImage from "../../../assets/images/verify.svg";
import sendIcon from "../../../assets/images/paper plane.svg";
import "./style.css";
import { useTranslation } from "react-i18next";
import { FormGroup, Form, Button, Col, Row, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { One_time_Token } from "../../../URLS/staticUrls";
import axios from "../../../URLS/Api/Api";
import thankyouIcon from "../../../assets/images/thankyouIcon.svg";
import sendandSignImage from "../../../assets/images/Send to Sign.svg";
import FirstStepImage from "../../../assets/images/Mask group.svg";
import SignatoryPlaceImage from "../../../assets/images/Place Signatories.svg";
import DrawImage from "../../../assets/images/Draw 1.svg";
import SignHeresystemImage from "../../../assets/images/Sign Here out the system.svg";
import { toast } from "react-toastify";
import ApiandWebhook from "../../CarouselComponent/ApiandWebhook";
import ScrollToTopButton from "../../../Components/Scroll/ScrolDown";

function ApiScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const containerRef = useRef(null);

  useEffect(() => {
    const scrollHandler = () => {
      const container = containerRef.current;
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const containerCenterX = containerRect.left + containerRect.width / 2;

        Array.from(container.children).forEach((child) => {
          const childRect = child.getBoundingClientRect();
          const childCenterX = childRect.left + childRect.width / 2;
          const distanceToCenter = Math.abs(containerCenterX - childCenterX);
          const scaleFactor = 1 + (500 - distanceToCenter) / 1000;

          child.style.transform = `scale(${scaleFactor})`;
        });
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", scrollHandler);
    scrollHandler();

    return () => {
      container.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm();

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      Message: "You got a message from Api Page contact as soon a possibile",
    };

    try {
      setIsLoading(true);

      const response = await axios.post("/Account/CreateContactUs", newData, {
        headers: {
          Authorization: `Bearer ${One_time_Token}`,
        },
      });
      if (response.data.statusCode === "1") {
        reset();
        setIsLoading(false);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 5000);
      } else {
        toast.error(t("contactus.toastErr"));
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <div className='loader'></div>
        </div>
      )}

      <ScrollToTopButton />

      <Modal show={showModal} backdrop='static' centered keyboard={false}>
        <Modal.Body style={{ padding: "40px" }}>
          <div className='message-box d-flex flex-column justify-content-center align-items-center'>
            <img src={thankyouIcon} alt='iconLoading' size={50} className='success-icon' />
            <h4 style={{ color: "#24B032", marginTop: "5px" }}>{t("contactus.thankyou")}</h4>
            <div className='mt-3'>
              <p className='m-0'>{t("contactus.youInquirey")}</p>
              <p>{t("contactus.findConfirmation")}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <LandingNav />

      <section>
        <ApiandWebhook />
      </section>

      <section>
        <div className='Apifeatures'>
          <div>
            <h1>{t("WebhookandAPi.Automate")}</h1>
            <p>
              {t("WebhookandAPi.companyDedicated")}
              <br /> {t("WebhookandAPi.cultureIamSigner")}
              <br />
              {t("WebhookandAPi.showcase")}
            </p>
          </div>

          <div className='scroll_container' ref={containerRef}>
            <div className='scroll_container_sub'></div>

            <div className='scroll_container_sub'>
              <img src={FirstStepImage} alt='First Step' style={{ transform: "scale(0.8)" }} />
            </div>
            <div className='scroll_container_sub'>
              <img src={SignatoryPlaceImage} alt='Signatory Place' style={{ transform: "scale(0.8)" }} />
            </div>
            <div className='scroll_container_sub'>
              <img src={sendandSignImage} alt='Send and Sign' style={{ transform: "scale(0.8)" }} />
            </div>
            <div className='scroll_container_sub'>
              <img src={DrawImage} alt='Draw' style={{ transform: "scale(0.8)" }} />
            </div>
            <div className='scroll_container_sub'>
              <img src={SignHeresystemImage} alt='Sign Here out the system' style={{ transform: "scale(0.8)" }} />
            </div>
          </div>
        </div>
      </section>

      <section className='featureApiBar'>
        <div>
          <h1 className='fw-bold'>{t("WebhookandAPi.Features")}</h1>
        </div>
        <div>
          <div className='featureApidiv'>
            <div className='featureApiChlid'>
              <img src={Clickimage} alt='imageLoading' style={{ height: "60px", width: "60px" }} />
              <label className='featuresApilabel'>{t("WebhookandAPi.simple")}</label>
              <p>{t("WebhookandAPi.SimpleAPIs")}</p>
            </div>
            <div className='featureApiChlid'>
              <img src={verifyImage} alt='imageLoading' style={{ height: "60px", width: "60px" }} />
              <label className='featuresApilabel'>{t("WebhookandAPi.secure")}</label>
              <p>{t("WebhookandAPi.securitytandards")}</p>
            </div>
            <div className='featureApiChlid'>
              <img src={daimondImage} alt='imageLoading' style={{ height: "60px", width: "60px" }} />
              <label className='featuresApilabel'>{t("WebhookandAPi.RELIABLE")}</label>
              <p>
                {t("WebhookandAPi.Well-documented")}
                <br />
                {t("WebhookandAPi.reliableSupport")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='formSection'>
          <div>
            <img src={sendIcon} alt='ImageLoading' />
            <h1>{t("WebhookandAPi.contactUs")}</h1>
            <p>{t("WebhookandAPi.yourContact")}</p>
          </div>

          <div class='vl'></div>

          <div>
            <Form className='mt-3' onSubmit={handleSubmit(onSubmit)}>
              <Row>
                {/* Name */}
                <Col md={5}>
                  <FormGroup className={`mb-2 ${errors.Name ? "has-error" : ""}`}>
                    <Form.Label className='form-label m-0'>
                      {t("contactus.name")}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("contactus.name")}
                      {...register("Name", { required: t("contactus.requiredName") })}
                      className={`form-control ${errors.Name ? "error-border" : ""}`}
                    />
                    {errors.Name && <div className='error'>{errors.Name.message}</div>}
                  </FormGroup>
                </Col>

                {/* Email */}
                <Col md={5}>
                  <FormGroup className={`mb-2 ${errors.EmailAddress ? "has-error" : ""}`}>
                    <Form.Label className='form-label m-0'>
                      <span> {t("contactus.EmailAddress")} </span>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("contactus.email")}
                      {...register("EmailAddress", {
                        required: t("contactus.emailRequired"),
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: t("contactus.emailAddress"),
                        },
                      })}
                      className={`form-control ${errors.EmailAddress ? "error-border" : ""}`}
                    />
                    {errors.EmailAddress && <div className='error'>{errors.EmailAddress.message}</div>}
                  </FormGroup>
                </Col>

                {/* Mobile Number */}
                <Col md={5}>
                  <FormGroup className={` mb-2${errors.MobileNo ? "has-error" : ""}`}>
                    <Form.Label className='form-label m-0'>
                      {t("contactus.MobileNumber")}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("contactus.MobileNumber")}
                      {...register("MobileNo", {
                        required: t("contactus.mobileRequired"),
                        pattern: {
                          value: /^[0-9]+$/,
                          message: t("contactus.ValidmobileNumber"),
                        },
                      })}
                      className={`form-control ${errors.MobileNo ? "error-border" : ""}`}
                    />
                    {errors.MobileNo && <div className='error'>{errors.MobileNo.message}</div>}
                  </FormGroup>
                </Col>
                {/* Submit Button */}
                <Col md={5}>
                  <Button type='submit' className='btn-singUp mt-4'>
                    {t("contactus.send")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default ApiScreen;
