import React, { useState } from "react";
import LandingNav from "../Navbar/LandingNav";
import { FormGroup, Form, Button, Col, Row, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./style.css";
import { useForm } from "react-hook-form";
import mapImg from "../../../assets/images/Component 6.svg";
import { FiPhoneCall } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import { IoMailOutline } from "react-icons/io5";
import Footer from "../Footer/Footer";
import ScrollToTopButton from "../../../Components/Scroll/ScrolDown";
import axios from "../../../URLS/Api/Api";
import { One_time_Token } from "../../../URLS/staticUrls";
import thankyouIcon from "../../../assets/images/thankyouIcon.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Contactus() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post("/Account/CreateContactUs", data, {
        headers: {
          Authorization: `Bearer ${One_time_Token}`,
        },
      });
      if (response.data.statusCode === "1") {
        reset();
        setIsLoading(false);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate("/");
        }, 5000);
      } else {
        toast.error(t("contactus.toastErr"));
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  

  return (
    <div>
      <LandingNav />

      <Modal show={showModal} backdrop='static' centered keyboard={false}>
        <Modal.Body style={{ padding: "40px" }}>
          <div className='message-box d-flex flex-column justify-content-center align-items-center'>
            <img src={thankyouIcon} alt='iconLoading' size={50} className='success-icon' />
            <h4 style={{ color: "#24B032", marginTop: "5px" }}>{t("contactus.thankyou")}</h4>
            <div className='mt-3'>
              <p className='m-0'>{t("contactus.youInquirey")}</p>
              <p>{t("contactus.findConfirmation")}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <>
        {isLoading && (
          <div className='loader-overlay'>
            <div className='loader'></div>
          </div>
        )}
        <div className='contact-col'>
          <div className='contact-parent container'>
            <Row className='contact-child'>
              <Col sm={12} md={5} order={{ sm: 2, md: 1 }}>
                <h4 className='form-heading fw-bold'>
                  {t("contactus.GetIn")}
                  <span style={{ color: "#0C9AD7" }}>{t("contactus.Touch")}</span>
                </h4>
                <label>{t("contactus.Feelfree")}</label>
                <Form className='mt-3' onSubmit={handleSubmit(onSubmit)}>
                  {/* Name */}
                  <FormGroup className={`mb-2 ${errors.Name ? "has-error" : ""}`}>
                    <Form.Label className='form-label'>
                      {t("contactus.name")}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("contactus.name")}
                      {...register("Name", { required: t("contactus.requiredName") })}
                      className={`form-control ${errors.Name ? "error-border" : ""}`}
                    />
                    {errors.Name && <div className='error'>{errors.Name.message}</div>}
                  </FormGroup>

                  {/* Email */}
                  <FormGroup className={`mb-2 ${errors.EmailAddress ? "has-error" : ""}`}>
                    <Form.Label className='form-label'>
                      <span> {t("contactus.EmailAddress")} </span>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("contactus.email")}
                      {...register("EmailAddress", {
                        required: t("contactus.emailRequired"),
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: t("contactus.emailAddress"),
                        },
                      })}
                      className={`form-control ${errors.EmailAddress ? "error-border" : ""}`}
                    />
                    {errors.EmailAddress && <div className='error'>{errors.EmailAddress.message}</div>}
                  </FormGroup>

                  {/* Mobile Number */}
                  <FormGroup className={` mb-2${errors.MobileNo ? "has-error" : ""}`}>
                    <Form.Label className='form-label'>
                      {t("contactus.MobileNumber")}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("contactus.MobileNumber")}
                      {...register("MobileNo", {
                        required: t("contactus.mobileRequired"),
                        pattern: {
                          value: /^[0-9]+$/,
                          message: t("contactus.ValidmobileNumber"),
                        },
                      })}
                      className={`form-control ${errors.MobileNo ? "error-border" : ""}`}
                    />
                    {errors.MobileNo && <div className='error'>{errors.MobileNo.message}</div>}
                  </FormGroup>

                  <FormGroup className={`mb-2 mt-2 ${errors.Message ? "has-error" : ""}`}>
                    <Form.Label className='form-label'>
                      {t("contactus.yourMessage")}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder={t("contactus.enterMessage")}
                      {...register("Message", {
                        required: t("contactus.msgfieldRequired"),
                      })}
                      className={`form-control ${errors.Message ? "error-border" : ""}`}
                    />
                    {errors.Message && <div className='error'>{errors.Message.message}</div>}
                  </FormGroup>

                  {/* Submit Button */}
                  <Button type='submit' className='btn-singUp mt-2'>
                    {t("contactus.send")}
                  </Button>
                </Form>

                <div style={{ display: "flex", flexDirection: "row", marginTop: "20px", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                    <FiPhoneCall size={20} />
                    <div className='contactusDetails'>
                      <label style={{ fontSize: "12px", fontWeight: "500" }}>{t("contactus.phone")}</label>
                      <span style={{ fontSize: "12px", color: "#0C9AD7" }}>+966 50 006 7158</span>
                      <span style={{ fontSize: "12px", color: "#0C9AD7" }}>+966 50 017 2382</span>
                      <span style={{ fontSize: "12px", color: "#0C9AD7" }}>+962 79 111 1282</span>
                      <span style={{ fontSize: "12px", color: "#0C9AD7" }}>+92 343 9650517</span>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                    <CiLocationOn size={22} />
                    <div className='contactusDetails'>
                      <label style={{ fontSize: "12px", fontWeight: "500" }}>{t("contactus.location")}</label>
                      <span style={{ fontSize: "12px", color: "#0C9AD7" }}>Riyadh, KSA</span>
                      <span style={{ fontSize: "12px", color: "#0C9AD7" }}>Amman, Jordan</span>
                      <span style={{ fontSize: "12px", color: "#0C9AD7" }}>Islamabad, Pakistan</span>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                    <IoMailOutline size={22} />
                    <div className='contactusDetails'>
                      <label style={{ fontSize: "12px", fontWeight: "500" }}>{t("contactus.Email")}</label>
                      <span style={{ fontSize: "12px", color: "#0C9AD7" }}>info@iamsigner.com</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={5} order={{ sm: 1, md: 2 }}>
                <img src={mapImg} alt='imageLoading' className='img-fluid mb-3 mb-md-0' style={{ maxWidth: "100%", width: "400px" }} />
              </Col>
            </Row>
          </div>
        </div>
      </>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
}

export default Contactus;
