import React, { useState, useEffect } from "react";
import LandingNav from "../Navbar/LandingNav";
import Footer from "../Footer/Footer";
import macImg from "../../../assets/images/Section About IamSigner English.svg";
import macbookImg from "../../../assets/images/Section About IamSigner Arabic.svg";
import macfullImg from "../../../assets/images/Macbook Pro 13_ Mockup.svg";
import "./style.css";
import ScrollToTopButton from "../../../Components/Scroll/ScrolDown";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <LandingNav />

      <section className='about-main'>
        <div className='aboutDesc'>
          <h2>{t("aboutus.OurMission")}</h2>
          <p>{t("aboutus.Spearhead")}</p>
          <p>{t("aboutus.Everything")}</p>
        </div>
      </section>

      <div className='imageBg'>
        <section>
          <div className='container'>
            <div className='row py-5'>
              <div className='col-12 col-md-6 order-md-2'>
                <img src={windowWidth < 768 ? macfullImg : document.dir === "rtl" ? macImg : macbookImg} alt='imageLoading' className='img-fluid' />

                {/* <img src={macbookImg} alt='imageLoading' className='img-fluid' /> */}
              </div>
              <div className='col-12 col-md-6 order-md-1'>
                <div style={{ padding: "100px 0px" }}>
                  <h2>{t("aboutus.AboutIamSigner")}</h2>
                  <ul>
                    <li>{t("aboutus.IamSignermakes")}</li>
                    <li>{t("aboutus.Simplifyyour")}</li>
                    <li>{t("aboutus.ProtectConfidentiality")}</li>
                    <li>{t("aboutus.Experiencesimple")}</li>
                    <li>{t("aboutus.EasilyOrganize")}</li>
                    <li>{t("aboutus.personalizedExperience")}</li>
                    <li>{t("aboutus.EnsureRegulatory")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='text-center text-md-center'>
                  <h1 className=''>{t("aboutus.OurTeam")}</h1>
                  <p>{t("aboutus.companyDedicated")}</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section>
          <div className='section4Div'>
            <div className='ImgBackground'>
              <div className='text-container'>
                <div style={{ backgroundColor: "#FFF", padding: "5px 10px", textAlign: "center", borderRadius: "12px" }}>
                  <label className='fw-bold fs-14'>{t("aboutus.OmarAlFarahid")}</label>
                  <p style={{ marginBottom: "0px", fontSize: "12px" }}>{t("aboutus.ProjectManager")}</p>
                </div>
              </div>
            </div>
            <div className='Img2Background'>
              <div className='text-container'>
                <div style={{ backgroundColor: "#FFF", padding: "5px 10px", textAlign: "center", borderRadius: "12px" }}>
                  <label className='fw-bold fs-14'>{t("aboutus.MohammedBassam")}</label>
                  <p style={{ marginBottom: "0px", fontSize: "12px" }}>{t("aboutus.ProjectManagementDirector")}</p>
                </div>
              </div>
            </div>
            <div className='Img3Background'>
              <div className='text-container'>
                <div style={{ backgroundColor: "#FFF", padding: "5px 10px", textAlign: "center", borderRadius: "12px" }}>
                  <label className='fw-bold fs-14'>{t("aboutus.MohammedHassan")}</label>
                  <p style={{ marginBottom: "0px", fontSize: "12px" }}>{t("aboutus.BusinessDevelopmentDirector")}</p>
                </div>
              </div>
            </div>
            <div className='Img4Background'>
              <div className='text-container'>
                <div style={{ backgroundColor: "#FFF", padding: "5px 10px", textAlign: "center", borderRadius: "12px" }}>
                  <label className='fw-bold fs-14'>{t("aboutus.MohammedHamdan")}</label>
                  <p style={{ marginBottom: "0px", fontSize: "12px" }}>{t("aboutus.DeveloperManager")}</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
}

export default AboutUs;
