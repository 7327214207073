  import React, { useState } from "react";
  import { Modal, Row, Col } from "react-bootstrap";
  import openAiImage from "../../assets/images/openAiImage.png";
  import secongImage from "../../assets/images/amico.svg";
  import thirdImage from "../../assets/images/openAiCarsule.svg";
  import Carousel from "react-bootstrap/Carousel";
  import { useTranslation } from "react-i18next";
  import "./carousel.css";

  const OpenAiCarsouel = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(true);

    return (
      <div>
        <Modal show={showModal} dialogClassName='custom-modal-width' centered onHide={() => setShowModal(false)}>
          <Modal.Header closeButton />
          <Modal.Body className='my-3'>
            <div>
              <Carousel data-bs-theme='dark' controls={false}>
                <Carousel.Item interval={2000}>
                  <Row>
                    <Col className='p-0'>
                      <div>
                        <img src={openAiImage} alt='ImageLoading' style={{ height: "200px", width: "100%" }} />
                      </div>
                    </Col>
                    <Col>
                      <div className='d-flex flex-column justify-content-center'>
                        <h4>{t("OpenAi.OpenAI")}</h4>
                        <p className='mt-3'>{t("OpenAi.NowGenerate")}</p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <Row>
                    <Col>
                      <div>
                        <img src={secongImage} alt='ImageLoading' style={{ height: "200px", width: "100%" }} />
                      </div>
                    </Col>
                    <Col>
                      <div className='d-flex flex-column justify-content-center'>
                        <h4>{t("OpenAi.DocumentsType")}</h4>
                        <p className='mt-3'>{t("OpenAi.CraftPath")}</p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <Row>
                    <Col>
                      <div>
                        <img src={thirdImage} alt='ImageLoading' style={{ height: "200px", width: "100%" }} />
                      </div>
                    </Col>
                    <Col>
                      <div className='d-flex flex-column justify-content-center'>
                        <h4>{t("OpenAi.FastGenerate")}</h4>
                        <p className='mt-3'>{t("OpenAi.SwiftlyProduces")}</p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  export default OpenAiCarsouel;
