import React, { useState, useEffect } from "react";
import apisImage from "../../assets/images/APiScreen.svg";
import WebhookImage from "../../assets/images/webhookApiImage.svg";
import { useTranslation } from "react-i18next";
import "./carousel.css";
import { Link } from "react-router-dom";
import { signUp_Urls } from "../../URLS/staticUrls";
import ApiImageForSmall from "../../assets/images/apiImageforSmall.svg";
import webhookArabicImage from "../../assets/images/webhhookArabic.svg";
import ApiArabicImage from "../../assets/images/ApiArabic.svg";
import smallWebhookImage from "../../assets/images/smallWebhookImg.svg";
import { Button, Modal, Collapse } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { CiFolderOn } from "react-icons/ci";

const ApiandWebhook = () => {
  const { t } = useTranslation();
  const Login_URL = signUp_Urls;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openSignatories, setOpenSignatories] = useState(false);
  const [openAuthentication, setOpenAuthentication] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [openSignFlow, setOpenSignFlow] = useState(false);

  const [showAddSignatoryModal, setShowAddSignatoryModal] = useState(false);
  const [showGetSignatoryModal, setShowGetSignatoryModal] = useState(false);
  const [showUpdateSignatoryModal, setShowUpdateSignatoryModal] = useState(false);
  const [showDeleteSignatoryModal, setShowDeleteSignatoryModal] = useState(false);

  const [showGenerateIntegrationAccessModal, setShowGenerateIntegrationAccessModal] = useState(false);

  const [showDeleteDocuments, setShowDeleteDocuments] = useState(false);
  const [showDownloadDocuments, setShowDownloadDocuments] = useState(false);
  const [showGetDocuments, setShowGetDocuments] = useState(false);

  const [showSignFlowCreate, setShowSignFlowCreate] = useState(false);
  const [showSignSupportingDoc, setShowSignSupportingDoc] = useState(false);
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [showGetSupportingDocs, setShowGetSupportingDocs] = useState(false);
  const [showSignTemplates, setShowSignTemplates] = useState(false);
  const [showSendAndSign, setShowSendAndSign] = useState(false);
  const [showSignUploadDocs, setShowSignUploadDocs] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Signatories

  const toggleAddSignatoryModal = () => {
    setShowAddSignatoryModal(!showAddSignatoryModal);
  };

  const toggleDeleteSignatoryModal = () => {
    setShowDeleteSignatoryModal(!showDeleteSignatoryModal);
  };

  const toggleGetSignatoryModal = () => {
    setShowGetSignatoryModal(!showGetSignatoryModal);
  };

  const toggleUpdtaeSignatoryModal = () => {
    setShowUpdateSignatoryModal(!showUpdateSignatoryModal);
  };

  //Authentication
  const ShowAccessTokenModal = () => {
    setShowGenerateIntegrationAccessModal(!showGenerateIntegrationAccessModal);
  };

  //Documents
  const toggleDeleteDocuments = () => {
    setShowDeleteDocuments(!showDeleteDocuments);
  };

  const toggleGetDocument = () => {
    setShowGetDocuments(!showGetDocuments);
  };

  const toggleDownloadDocument = () => {
    setShowDownloadDocuments(!showDownloadDocuments);
  };

  //Sign Flow
  const toggleSignFlowCreate = () => {
    setShowSignFlowCreate(!showSignFlowCreate);
  };
  const toggleSignFlowAddSupportingDocs = () => {
    setShowSignSupportingDoc(!showSignSupportingDoc);
  };

  const toggleSignFlowAddTemplate = () => {
    setShowAddTemplate(!showAddTemplate);
  };

  const toggleSignFlowGetSupportingDocs = () => {
    setShowGetSupportingDocs(!showGetSupportingDocs);
  };

  const toggleSignFlowGetTemplates = () => {
    setShowSignTemplates(!showSignTemplates);
  };

  const toggleSignFlowSendAndSign = () => {
    setShowSendAndSign(!showSendAndSign);
  };

  const toggleSignFlowUploadDocuments = () => {
    setShowSignUploadDocs(!showSignUploadDocs);
  };

  return (
    <div>
      <div className='apiSection'>
        <div className='apiSectionDiv'>
          <div>
            <h1>{t("WebhookandAPi.IntegrationAuthentication")}</h1>
            <p>{t("WebhookandAPi.Ensuresecure")}</p>
          </div>

          <div>
            <h1>{t("WebhookandAPi.IamSigner")}</h1>
            <p>{t("WebhookandAPi.AutomaticallyGenerate")}</p>
          </div>

          <div className='my-3'>
            <label className='fw-bold'>{t("WebhookandAPi.Introduction")}</label>

            {/*Authentication  */}
            <div>
              <Button
                className='d-flex align-items-center p-0 gap-1 my-2'
                onClick={() => setOpenAuthentication(!openAuthentication)}
                variant='none'
                style={{ border: "none", color: "black" }}
                aria-controls='example-collapse-text'
                aria-expanded={openAuthentication}
              >
                {openAuthentication ? (
                  <>
                    <IoIosArrowDown />
                    <CiFolderOn size={22} color='#0c9ad7' />
                    {t("WebhookandAPi.Authentication")}
                  </>
                ) : (
                  <>
                    <IoIosArrowForward />
                    <CiFolderOn size={22} color='#0c9ad7' />
                    {t("WebhookandAPi.Authentication")}
                  </>
                )}
              </Button>
              <Collapse in={openAuthentication} className='mt-2'>
                <div id='example-collapse-text'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-post'>POST</span>
                      <button className='mb-0 http-button' onClick={ShowAccessTokenModal}>
                        {t("WebhookandAPi.GenerateIntegrationAccessToken")}
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            {/* Signatories */}
            <div>
              <Button
                className='d-flex align-items-center p-0 gap-1 my-2'
                onClick={() => setOpenSignatories(!openSignatories)}
                variant='none'
                style={{ border: "none", color: "black" }}
                aria-controls='example-collapse-text'
                aria-expanded={openSignatories}
              >
                {openSignatories ? (
                  <>
                    <IoIosArrowDown />
                    <CiFolderOn size={22} color='#0c9ad7' />
                    {t("WebhookandAPi.Signatories")}
                  </>
                ) : (
                  <>
                    <IoIosArrowForward />
                    <CiFolderOn size={22} color='#0c9ad7' />
                    {t("WebhookandAPi.Signatories")}
                  </>
                )}
              </Button>
              <Collapse in={openSignatories} className='my-2'>
                <div id='example-collapse-text'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-post'>POST</span>
                      <button className='mb-0 http-button' onClick={toggleAddSignatoryModal}>
                        {t("WebhookandAPi.AddSignatories")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-put'>PUT</span>
                      <button className='mb-0 http-button' onClick={toggleUpdtaeSignatoryModal}>
                        {t("WebhookandAPi.UpdateSignatories")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-put'>PUT</span>
                      <button className='mb-0 http-button' onClick={toggleDeleteSignatoryModal}>
                        {t("WebhookandAPi.DeleteSignatories")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-get'>GET</span>
                      <button className='mb-0 http-button' onClick={toggleGetSignatoryModal}>
                        {t("WebhookandAPi.GetSignatories")}
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            {/* SignFlow */}
            <div>
              <Button
                className='d-flex align-items-center p-0 gap-1 my-2 '
                onClick={() => setOpenSignFlow(!openSignFlow)}
                variant='none'
                style={{ border: "none", color: "black" }}
                aria-controls='example-collapse-text'
                aria-expanded={openSignFlow}
              >
                {openSignFlow ? (
                  <>
                    <IoIosArrowDown />
                    <CiFolderOn size={22} color='#0c9ad7' />
                    {t("WebhookandAPi.SignFlow")}
                  </>
                ) : (
                  <>
                    <IoIosArrowForward />
                    <CiFolderOn size={22} color='#0c9ad7' />
                    {t("WebhookandAPi.SignFlow")}
                  </>
                )}
              </Button>
              <Collapse in={openSignFlow} className='mt-2'>
                <div id='example-collapse-text'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-post'>POST</span>
                      <button className='mb-0 http-button' onClick={toggleSignFlowCreate}>
                        {t("WebhookandAPi.CreateRegisterSign")}
                      </button>
                    </div>

                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-post'>POST</span>
                      <button className='mb-0 http-button' onClick={toggleSignFlowAddSupportingDocs}>
                        {t("WebhookandAPi.AddSupportingDocs")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-put'>PUT</span>
                      <button className='mb-0 http-button' onClick={toggleSignFlowAddTemplate}>
                        {t("WebhookandAPi.AddTemplate")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-get'>GET</span>
                      <button className='mb-0 http-button' onClick={toggleSignFlowGetSupportingDocs}>
                        {t("WebhookandAPi.GetSupportingDocs")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-get'>GET</span>
                      <button className='mb-0 http-button' onClick={toggleSignFlowGetTemplates}>
                        {t("WebhookandAPi.GetTemplates")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-post'>POST</span>
                      <button className='mb-0 http-button' onClick={toggleSignFlowSendAndSign}>
                        {t("WebhookandAPi.SendAndSign")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-post'>POST</span>
                      <button className='mb-0 http-button' onClick={toggleSignFlowUploadDocuments}>
                        {t("WebhookandAPi.UploadDocuments")}
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            {/* Document */}
            <div>
              <Button
                className='d-flex align-items-center p-0 gap-1 my-2'
                onClick={() => setOpenDocuments(!openDocuments)}
                variant='none'
                style={{ border: "none", color: "black" }}
                aria-controls='example-collapse-text'
                aria-expanded={openDocuments}
              >
                {openDocuments ? (
                  <>
                    <IoIosArrowDown />
                    <CiFolderOn size={22} color='#0c9ad7' />
                    {t("WebhookandAPi.Documents")}
                  </>
                ) : (
                  <>
                    <IoIosArrowForward />
                    <CiFolderOn size={22} color='#0c9ad7' />
                    {t("WebhookandAPi.Documents")}
                  </>
                )}
              </Button>
              <Collapse in={openDocuments} className='my-2'>
                <div id='example-collapse-text'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-put'>PUT</span>
                      <button className='mb-0 http-button' onClick={toggleDeleteDocuments}>
                        {t("WebhookandAPi.DeleteDocuments")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-get'>GET</span>
                      <button className='mb-0 http-button' onClick={toggleDownloadDocument}>
                        {t("WebhookandAPi.DownloadDocument")}
                      </button>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='http-method-label http-get'>GET</span>
                      <button className='mb-0 http-button' onClick={toggleGetDocument}>
                        {t("WebhookandAPi.GetDocumentStatus")}
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>

          {/* <div className='my-4'>
            <Link to={Login_URL}>
              <button className='getStartedbtn'>{t("service.GetStarted")}</button>
            </Link>
          </div> */}
        </div>

        <img src={windowWidth < 768 ? ApiImageForSmall : document.dir === "rtl" ? ApiArabicImage : apisImage} alt='imageLoading' className='apiImage' />
      </div>
      <hr  className="my-0"/>
      <div className='apiSection'>
        <div className='apiSectionDiv'>
          <div>
            <h1>{t("WebhookandAPi.Notifications")}</h1>
            <p>{t("WebhookandAPi.StayInformed")}</p>
          </div>

          <div>
            <h1>{t("WebhookandAPi.KeyFeatures")}</h1>
            <p>{t("WebhookandAPi.Experience")}</p>
          </div>

          <div className='apiFeatureContainer'>
            <div className='apiFeaturediv'>
              <div className='apiFeatureChild'>
                <label className='apiFeaturelabel'>{t("WebhookandAPi.Instant")}</label>
                <p>{t("WebhookandAPi.ReceiveNotifications")}</p>
              </div>
              <div className='apiFeatureChild'>
                <label className='apiFeaturelabel'>{t("WebhookandAPi.FlexibleConfiguration")}</label>
                <p>{t("WebhookandAPi.ConfigureWebhooks")}</p>
              </div>
              <div className='apiFeatureChild'>
                <label className='apiFeaturelabel'>{t("WebhookandAPi.CustomizableIntegration")}</label>
                <p>{t("WebhookandAPi.Tailorwebhook")}</p>
              </div>
              <div className='apiFeatureChild'>
                <label className='apiFeaturelabel'>{t("WebhookandAPi.SecureAuthentication")}</label>
                <p>{t("WebhookandAPi.Secureyourwebhook")}</p>
              </div>
            </div>
          </div>

          <div className='my-4'>
            <Link to={Login_URL}>
              <button className='getStartedbtn'>{t("service.GetStarted")}</button>
            </Link>
          </div>
        </div>

        <img src={windowWidth < 768 ? smallWebhookImage : document.dir === "rtl" ? webhookArabicImage : WebhookImage} alt='imageLoading' className='apiImage' />
      </div>

      {/* Signatories */}

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showAddSignatoryModal} onHide={toggleAddSignatoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.AddSignatory")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.AddSignatoriesAPI")}</li>
            <li className='my-2'>{t("WebhookandAPi.Uponsuccessfulexecution")}</li>
            <li className='my-2'>{t("WebhookandAPi.Byutilizingthis")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showDeleteSignatoryModal} onHide={toggleDeleteSignatoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.DeleteSignatory")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>

          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.DeleteSignatoryAPI")} </li>
            <li className='my-2'>{t("WebhookandAPi.theAPIrequest")}</li>
            <li className='my-2'>{t("WebhookandAPi.Byincorporating")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showUpdateSignatoryModal} onHide={toggleUpdtaeSignatoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.UpdateSignatory")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.UpdateSignatoryAPI")}</li>
            <li className='my-2'>{t("WebhookandAPi.UpdateUponsuccessful")} </li>
            <li className='my-2'>{t("WebhookandAPi.Byleveraging")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showGetSignatoryModal} onHide={toggleGetSignatoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.GetSignatory")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.GetSignatoriesAPI")} </li>
            <li className='my-2'>{t("WebhookandAPi.AdditionallyRequest")} </li>
            <li className='my-2'>{t("WebhookandAPi.UponsuccessfulGetSignatory")} </li>
            <li className='my-2'>{t("WebhookandAPi.ByUtilizing")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      {/* Authorization */}

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showGenerateIntegrationAccessModal} onHide={ShowAccessTokenModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.GenerateIntegration")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.TheGenerateIntegration")} </li>
            <li className='my-2'>{t("WebhookandAPi.UponsuccessfulGenerateIntegration")}</li>
            <li className='my-2'>{t("WebhookandAPi.ByutilizingGenerateIntegration")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      {/* Documents */}

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showDeleteDocuments} onHide={toggleDeleteDocuments}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.DeleteDocuments")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.DeleteDocumentsApi")}</li>
            <li className='my-2'>{t("WebhookandAPi.ByincorporatingDelete")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showGetDocuments} onHide={toggleGetDocument}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.GetDocument")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.UponreceivingGET")}</li>
            <li className='my-2'>{t("WebhookandAPi.ByGetLeveraging")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showDownloadDocuments} onHide={toggleDownloadDocument}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.DownloadDoc")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.DownloadDocumentAPI")}</li>
            <li className='my-2'>{t("WebhookandAPi.functionalityallows")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      {/* Sign Flow */}

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showSignFlowCreate} onHide={toggleSignFlowCreate}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.CreateRegister")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.TheCreateRegister")}</li>
            <li className='my-2'>{t("WebhookandAPi.ExecutionAPIrequest")} </li>
            <li className='my-2'>{t("WebhookandAPi.ByRegisterAPI")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showSignSupportingDoc} onHide={toggleSignFlowAddSupportingDocs}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.AddSupportingDocuments")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.SupportingDocumentsAPI")}</li>
            <li className='my-2'>{t("WebhookandAPi.successfulSupportingDocumentsAPI")} </li>
            <li className='my-2'>{t("WebhookandAPi.ByutilizingSupportingDocumentsAPI")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showAddTemplate} onHide={toggleSignFlowAddTemplate}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.Addtemplate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.AddTemplateAPI")}</li>
            <li className='my-2'>{t("WebhookandAPi.successfulAddTemplate")}</li>
            <li className='my-2'>{t("WebhookandAPi.leveragingAPiAddTemplate")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showGetSupportingDocs} onHide={toggleSignFlowGetSupportingDocs}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.GetSupportingDocuments")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.GetSupportingDocsApi")} </li>
            <li className='my-2'>{t("WebhookandAPi.Uponreceivingvalidrequest")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showSignTemplates} onHide={toggleSignFlowGetTemplates}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.GetTemp")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.GetTemplatesAPI")}</li>
            <li className='my-2'>{t("WebhookandAPi.executionGetTemplatesAPI")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showSendAndSign} onHide={toggleSignFlowSendAndSign}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.SendandSign")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.AdditionallySendandSign")}</li>
            <li className='my-2'>{t("WebhookandAPi.UponreceivingSend")}</li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' keyboard={false} dialogClassName='custom-modal-width' show={showSignUploadDocs} onHide={toggleSignFlowUploadDocuments}>
        <Modal.Header closeButton>
          <Modal.Title>{t("WebhookandAPi.UploadDocument")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fw-bold'>{t("WebhookandAPi.Purpose")}</p>
          <ul className='mx-3'>
            <li className='my-2'>{t("WebhookandAPi.UploadDocumentsAPI")}</li>
            <li className='my-2'>{t("WebhookandAPi.AdditionallyApi")}</li>
            <li className='my-2'>{t("WebhookandAPi.AdditionallyrequestSend")} </li>
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApiandWebhook;
