import React from "react";
import LandingNav from "../Navbar/LandingNav";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

function PrivacyandPolicy() {
  const { t } = useTranslation();

  return (
    <div style={{ height: "100vh" }}>
      <LandingNav />
      <div className='privacyPolicy'>
        <h2 className='fw-bold'>{t("privacy&Policy.OverviewImsigner")}</h2>
        <label style={{ fontWeight: "600" }}>{t("privacy&Policy.LastModified")}</label>
        <p className='mt-3 opacity-1'>
          {t("privacy&Policy.PrivacyPolicy")}
          <span style={{ color: "#0979A9", fontWeight: "700" }}> IamSigner </span>
          {t("privacy&Policy.services")}
        </p>
        <div className='mt-3'>
          <ul>
            <h4>{t("privacy&Policy.Information")}</h4>
            <li style={{ marginLeft: "20px" }}>
              {t("privacy&Policy.PersonalInformation")}
              <span style={{ color: "#0979A9", fontWeight: "700" }}> IamSigner </span> {t("privacy&Policy.services")}.
            </li>
            <li style={{ marginLeft: "20px" }}>{t("privacy&Policy.TransactionInformation")}</li>
            <li style={{ marginLeft: "20px" }}>{t("privacy&Policy.LogData")}</li>
          </ul>
        </div>
        <div className='mt-3'>
          <h4>{t("privacy&Policy.HowInformation")}</h4>
          <ul>
            <li style={{ marginLeft: "20px" }}>
              {t("privacy&Policy.providing")} <span style={{ color: "#0979A9", fontWeight: "700" }}> IamSigner </span> {t("privacy&Policy.services")}
            </li>
            <li style={{ marginLeft: "20px" }}>{t("privacy&Policy.ImprovingExperience")}</li>
            <li style={{ marginLeft: "20px" }}>{t("privacy&Policy.Communicating")}</li>
            <li style={{ marginLeft: "20px" }}>{t("privacy&Policy.AnalyzingWebsite")}</li>
            <li style={{ marginLeft: "20px" }}>{t("privacy&Policy.ComplyingLegal")}</li>
          </ul>
        </div>
        <div className='mt-3'>
          <h4>{t("privacy&Policy.InformationSharing")}</h4>
          <p>{t("privacy&Policy.Wedonotsell")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("privacy&Policy.Security")}</h4>
          <p className='mt-3 opacity-1'>{t("privacy&Policy.Weimplement")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("privacy&Policy.Cookies")}</h4>
          <p className='mt-3 opacity-1'>{t("privacy&Policy.OurWebsite")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("privacy&Policy.Third-PartyLinks")}</h4>
          <p>{t("privacy&Policy.Ourwebsite")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("privacy&Policy.Changesto")}</h4>
          <p className='mt-3 opacity-1'>{t("privacy&Policy.Wereserve")}</p>
        </div>
        <div className='mt-3'>
          <h4>{t("privacy&Policy.ContactUs")}</h4>
          <p className='mt-3 opacity-1'>
            {t("privacy&Policy.questionsConcerns")}
            <span style={{ color: "#0979A9", fontWeight: "700" }}> www.IamSigner.com </span>
            {t("privacy&Policy.Byusing")}
            <span style={{ color: "#0979A9", fontWeight: "700" }}>IamSigner </span>
            {t("privacy&Policy.servicesyou")}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyandPolicy;
